<template>
  <div class="flex">
    <VueDatePicker v-model="date" model-type="yyyy-MM-dd" range @cleared="cleared"
                   placeholder="Выберите диапазон"></VueDatePicker>
  </div>
  <div v-if="usersWhiteList">
    <ag-grid-vue
        :columnDefs="columnDefs.value"
        :rowData="rowData"
        :rowHeight="70"
        :headerHeight="90"
        :enableCellTextSelection="true"
        :ensureDomOrder="true"
        :pagination="true"
        :paginationPageSize="countOnPage"
        :defaultColDef="defaultColDef"
        :suppressRowHoverHighlight="true"
        :suppressPaginationPanel="true"
        :suppressRowClickSelection="true"
        :suppressRowTransform="true"
        style="width: 100%"
        domLayout="autoHeight"
        @selection-changed="onRowSelected"
        @grid-ready="onGridReady"
        @filterChanged="filterChanged"
    ></ag-grid-vue>

    <v-pagination
        v-if="gridApi"
        v-model="page"
        @update:modelValue="changePage"
        :pages="gridApi.paginationGetTotalPages()"
        :range-size="1"
        active-color="transparent"
    />

    <div
        v-if="gridApi && gridApi.paginationGetTotalPages()"
        class="pagination-info"
    >
      <p>
        Показаны строки с {{ countOnPage * (page - 1) + 1 }} по
        {{ gridApi.paginationProxy.bottomDisplayedRowIndex + 1 }} из
        {{ gridApi.paginationGetRowCount() }}
      </p>
      <p>
        Страница {{ gridApi.paginationGetCurrentPage() + 1 }} из
        {{ gridApi.paginationGetTotalPages() }}
      </p>
    </div>
    <div class="pagination-info" v-else>Нет результатов</div>
  </div>
  <div v-else class="table-preloader">
    <preloader/>
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "ag-grid-community/dist/styles/ag-grid.css";
import {AgGridVue} from "ag-grid-vue3";
import {reactive, ref} from "@vue/reactivity";
import {useStore} from "vuex";
import {computed, onBeforeMount, watch} from "@vue/runtime-core";
import Preloader from "../Technical/Preloader.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  name: "staff-table",
  components: {
    VPagination,
    AgGridVue,
    Preloader,
    VueDatePicker
  },
  setup() {
    const store = useStore(),
        gridApi = ref(null),
        columnApi = ref(null);

    const onGridReady = (params) => {
      gridApi.value = params.api;
      columnApi.value = params.columnApi;
    };
    const countOnPage = ref(15),
        page = ref(1),
        isPreloader = ref(false)
    const date = ref();
    const dateFrom = new Date();
    const dateTo = new Date(new Date().setDate(dateFrom.getDate() - 7));
    date.value = [{date_from: dateFrom, date_to: dateTo}];

    const usersWhiteList = computed(() => store.state.users.usersWhiteList),
        rowData = computed(() => {
          return usersWhiteList.value.map((user) => {
            return {
              id: user.id,
              name: user.name,
              email: user.email,
              free_count: user.free_count,
              active_count: user.active_count,
              issued_count: user.issued_count,
              total: user.total,
            };
          });
        });

    const columnDefs = reactive({
      value: [
        {
          field: "id",
          hide: true,
        },
        {
          field: "name",
          headerName: "Название",
          wrapText: true,
          minWidth: 255,
        },
        {
          field: "email",
          headerName: "Электронная почта",
          wrapText: true,
          minWidth: 255,
        },
        {
          field: "free_count",
          headerName: "Неначатых тестов",
          wrapText: true,
          minWidth: 255,
        },
        {
          field: "active_count",
          headerName: "Проходят тесты",
          wrapText: true,
          minWidth: 255,
        },
        {
          field: "issued_count",
          headerName: "Пройдено тестов",
          wrapText: true,
          minWidth: 255,
        },
        {
          field: "total",
          headerName: "Всего тестов",
          wrapText: true,
          minWidth: 255,
        },
      ],
    });
    const defaultColDef = {
      suppressMovable: true,
      flex: 1,
      autoHeight: true,
    };
    const getUsers = () => {
      store.dispatch("users/getUsersWhiteList");
    }

    onBeforeMount(() => {
      getUsers()
    });

    const getUsersWhiteListBySearch = (date) => {
      store
          .dispatch("users/getUsersWhiteListBySearch", date.value)
    };

    const changePage = (page) => {
      gridApi.value.paginationGoToPage(page - 1);
    };

    watch(date, () => {
      if (date.value !== null) {
        getUsersWhiteListBySearch(date)
      }
    });

    watch(countOnPage, () => {
      gridApi.value.paginationSetPageSize(countOnPage.value);
      page.value = 1;
    });

    const filterChanged = () => {
      page.value = 2;
      page.value = 1;
    };

    const cleared = () => {
      getUsers()
    }

    return {
      countOnPage,
      isPreloader,
      page,
      changePage,
      usersWhiteList,
      columnDefs,
      rowData,
      defaultColDef,
      gridApi,
      onGridReady,
      columnApi,
      filterChanged,
      date,
      cleared,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
